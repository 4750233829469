import { axiosDefaultInstance } from '@/http/default'
import type Place from '@/model/Place'
import type { PlaceRequest } from '@/model/Place'

import { useProfileStore } from '@/store/profile.module'
import type { AxiosInstance } from 'axios'
import handleError from './util/ErrorHandler'
import type { ServiceError } from './util/ServiceError'
import type Page from '@/model/Page'

class PlaceService {
  private axiosInstance: AxiosInstance
  private storeProfile: any

  constructor() {
    this.axiosInstance = axiosDefaultInstance
    // this.storeProfile = useProfileStore()
  }

  async findAll(): Promise<Place[] | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []
    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Place[]>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/place`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async findAllV2(pageNumber?: any, pageSize?: any): Promise<Page<Place> | ServiceError> {
    this.storeProfile = useProfileStore()

    const params: string[][] = []
    if (pageSize) {
      params.push(['pageSize', pageSize])
    }
    if (pageNumber) {
      params.push(['pageNumber', pageNumber])
    }
    const searchParams = new URLSearchParams(params)

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.get<Page<Place>>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/place/v2`,
        { params: searchParams }
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async createPlace(place: PlaceRequest): Promise<Place | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.post<Place>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/place`,
        place
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async updatePlace(place: PlaceRequest): Promise<Place | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.put<Place>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/place/${place.id}`,
        place
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }

  async deletePlace(id: string): Promise<Place | ServiceError> {
    this.storeProfile = useProfileStore()

    const companyId: string = this.storeProfile.selectedCompany.id

    try {
      const networkResponse = await this.axiosInstance.delete<Place>(
        `${import.meta.env.VITE_API_BASE_URL}/company/${companyId}/place/${id}`
      )
      return networkResponse.data
    } catch (error) {
      return handleError(error)
    }
  }
}

export const placeService = new PlaceService()
