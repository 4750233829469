import { authService } from '@/services/AuthService'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { useProfileStore } from '@/store/profile.module'
import { Role } from '@/model/Employee'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('../views/CustomersView.vue')
    },
    {
      path: '/customers/:customerId',
      name: 'customerDetail',
      component: () => import('../views/CustomerDetailView.vue')
    },
    {
      path: '/vehicles',
      name: 'vehicles',
      component: () => import('../views/VehiclesView.vue')
    },
    {
      path: '/vehicles/:vehicleId',
      name: 'vehicleDetail',
      component: () => import('../views/VehicleDetailView.vue')
    },
    {
      path: '/places',
      name: 'places',
      component: () => import('../views/PlacesView.vue')
    },
    {
      path: '/prices',
      name: 'prices',
      component: () => import('../views/PricesView.vue')
    },
    {
      path: '/prices/:priceId',
      name: 'priceDetail',
      component: () => import('../views/PriceDetailView.vue')
    },
    {
      path: '/employees',
      name: 'employees',
      component: () => import('../views/EmployeesView.vue')
    },
    {
      path: '/refuels',
      name: 'refuels',
      component: () => import('../views/RefuelsView.vue')
    },
    {
      path: '/deliveryNotes',
      name: 'deliveryNotes',
      component: () => import('@/views/DeliveryNotesView.vue')
    },
    {
      path: '/deliveryNotes/:deliveryNoteId',
      name: 'deliveryNoteDetail',
      component: () => import('../views/DeliveryNoteDetailView.vue')
    },
    {
      path: '/bills',
      name: 'bills',
      component: () => import('../views/BillsView.vue')
    },
    {
      path: '/bills/:billId',
      name: 'billDetail',
      component: () => import('../views/BillDetailView.vue')
    },
    {
      path: '/newBill',
      name: 'newBill',
      component: () => import('@/views/NewBillView.vue')
    },
    {
      path: '/expenses',
      name: 'expenses',
      component: () => import('../views/ExpensesView.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/ProfileView.vue'),
      children: [
        {
          path: 'deliveryNotesConfig',
          name: 'deliveryNotesConfig',
          component: () => import('@/views/DeliveryNotesConfigView.vue')
        },
        {
          path: 'billsConfig',
          name: 'billsConfig',
          component: () => import('@/views/BillsConfigView.vue')
        },
        {
          path: 'nimas',
          name: 'nimas',
          component: () => import('../views/NimasView.vue')
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue')
    },
    {
      path: '/sharedLink',
      name: 'sharedLink',
      component: () => import('../views/SharedLinkView.vue')
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: () => import('../views/UnauthorizedView.vue')
    }
  ]
})

router.beforeResolve(async (to, from) => {
  const storeProfile = useProfileStore()

  // make sure the user is authenticated
  // ❗️ Avoid an infinite redirect
  if (!(await authService.isAuthenticated()) && to.name !== 'login' && to.name !== 'sharedLink') {
    // redirect the user to the login page
    return 'login'
  }

  if (
    (await authService.isAuthenticated()) &&
    storeProfile.selectedCompany.role !== Role.ADMIN &&
    to.name !== 'unauthorized'
  ) {
    // Unauthorized user
    return '/unauthorized'
  }

  if (
    (await authService.isAuthenticated()) &&
    storeProfile.selectedCompany.role === Role.ADMIN &&
    (to.name === 'login' || to.name === 'unauthorized')
  ) {
    // redirect the user to the home page
    return '/'
  }
})

export default router
